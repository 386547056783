import SimpleBar from 'pnp_core_bundle/simplebar/simplebar';
import { manageNetworkPopupEventListeners, manageDisplaySectionMode, managerDisruptionHeader } from '../../core/event-listeners'
import { positionBoard } from 'pnp_core_bundle/structure/board/board_dynamic_position';

export function getCurrentDisruptions(element) {
    let result = null;
    
    // Ajax Call
    JKisio.ajax({
        url: Routing.generate(
            'traffic_result',
            { type_product: (Kisio.type_product !== undefined) ? Kisio.type_product : '',}
        ),
        success: function (data) {
            if (data.code == 200) {
                element.innerHTML = data.response;
                try {
                    new SimpleBar(element);
                } catch (e) {
                    console.log(e);
                }

                managerDisruptionHeader();

                // add network popup event listeners
                manageNetworkPopupEventListeners();

                // manage click on modes
                manageDisplaySectionMode();

                updatePositionBoard();
            }
        },
        error: function (error) {
            console.log('call api error (traffic)');
        }
    });

    return result;
}

export function updatePositionBoard() {
    //Position board container
    const positionBoardInstance = new positionBoard();
    const widthBody = JKisio('body').outerWidth(true);
    const boardElement = JKisio('#board_container');
    if (boardElement.length > 0)
        var topBoard = parseInt(boardElement.css('top').replace(/[^-\d\.]/g, ''), 10);
    positionBoardInstance.calculate(topBoard ?? 0, widthBody, 600, 'init');
}
