import SimpleBar from '../../simplebar/simplebar';
import { isIE9 } from '../../plugnplay/browser';

Kisio.loadBoardEvents = Kisio.loadBoardEvents || {};
Kisio.loadBoardEvents.models = Kisio.loadBoardEvents.models || {};
Kisio.loadBoardEvents.models.libModels = Kisio.loadBoardEvents.models.libModels || {};

Kisio.loadBoardEvents.models.libModels.calculateTopDisplayBoard = Backbone.Model.extend({
    params: {
        accordionContentClass: '.ui-accordion-content',
        journeySearchAutocomplete: '.journey-search-autocomplete',
        journeyPrecisionInfo: '.precision-form-info',
        scheduleSearchAutocomplete: '.schedule_stop_area_lines',
        scheduleLineClass: '.ctp-line-schedule-infos',
        boardContainerId: '#board_container',
        boardTitleId: '#board_title_container',
        boardModesId :'#journey-search-board-modes',
        formSubmitId: '#journey-form-submit, .precision-form-submit, .precision-form-next, #ctp-searchProximity',
        boardFooterId: '#board_footer',
        proximityHeaderId: '#title_container',
        formModifyId: '#journey-form-modify',
        formNewSearchId: '#journey-form-new-search',
        precisionFromClass: '.ctp-precision-from',
        precisionToClass: '.ctp-precision-to',
        proximityResults: '#proximity-results',
        favoritesPage: '#ctp-favorites',
        precisionFormId: '#ctp-precisionForm',
        resultClass: '.kisio-result',
        accordionContentDate: '#ui-accordion-content-now',
        buttonBackClass: '.result-back-button, .precision-back-button',
        resultSummaryClass: '.kisio-result-summary',
        initValue: '',
        linesSearchResultsClass: '.ct-lines-search-results',
        proximityShowHideStopsButton: '#proximity-show-hide-stops',
        trafficClass: '.ctp-traffic'
    },

    /**
     * Calculate and animate Board Size and Position
     * @param topBoard
     * @param widthBody
     * @param breakPoint
     * @param status
     * @param scrollPosition
     * @param scheduleSelected
     */
    calculate: function (topBoard, widthBody, breakPoint, status, scrollPosition, scheduleSelected) {
        let el;
        let subHeigth;
        let _this;
        /* Breakpoint for responsive*/
        if (widthBody < breakPoint) {
            if (JKisio(this.params.accordionContentClass).hasClass('show')) {
                JKisio(this.params.boardContainerId).css({'top': '1%'});
            } else if (JKisio(this.params.resultClass).length > 0) {
                JKisio(this.params.boardContainerId).css({'top': '0'});
            } else {
                JKisio(this.params.boardContainerId).css({'top': '2%'});
            }
            // Manage the GET access for Stop_Area x Line Results
            if (scheduleSelected !== undefined && scheduleSelected !== '') {
                JKisio(document.getElementById(scheduleSelected)).trigger('mouseover').trigger('click');
            }
        } else {
            let heightBody = JKisio('body').outerHeight(true);
            let heightBoard = JKisio('#board_container').outerHeight(true);
            if (this.params.initValue === '') {
                this.params.initValue = heightBoard;
            }
            if (!JKisio(this.params.accordionContentClass + ', ' + this.params.precisionToClass).is(":visible") && status !== 'init') {
                heightBoard = this.params.initValue;
            }
            let marginTop = (heightBody * 2) / 100;
            let sumOfHeightBoard = (marginTop * 2) + heightBoard + topBoard;
            if (sumOfHeightBoard > heightBody) {
                let newTopBoard = topBoard - (sumOfHeightBoard - heightBody);
                if (newTopBoard < marginTop) {
                    newTopBoard = marginTop;
                    //
                    let headerBoard = this.getHeightHeaderBoard() + 2;
                    let newHeight = heightBody - (marginTop * 2 + headerBoard);
                    if (window.Kisio.type_product === 'standalone') {
                        newHeight = newHeight - 70;
                    }

                    // add scroll when accordion content is resized
                    if (JKisio(this.params.accordionContentClass + '.show').is(":visible")) {
                        headerBoard = headerBoard + JKisio(this.params.formModifyId).outerHeight(true);
                        JKisio(this.params.accordionContentClass + '.show').css({
                            'height': heightBody - (marginTop * 2 + headerBoard)
                        });
                        if (!isIE9) {
                            try {
                                new SimpleBar(JKisio(this.params.accordionContentClass + '.show')[0]);
                            } catch (e) {
                                console.log(e);
                            }
                        }
                    }
                    if (JKisio(this.params.precisionFromClass).is(":visible") && JKisio('#mapid').is(':visible')) {
                        JKisio(this.params.precisionFromClass).animate({'height': newHeight});
                        if (!isIE9) {
                            try {
                                new SimpleBar(JKisio(this.params.precisionFromClass)[0]);
                            } catch (e) {
                                console.log(e);
                            }
                        }
                    }
                    if (JKisio(this.params.precisionToClass).is(":visible") && JKisio('#mapid').is(':visible')) {
                        JKisio(this.params.precisionToClass).animate({'height': newHeight});
                        if (!isIE9) {
                            try {
                                new SimpleBar(JKisio(this.params.precisionToClass)[0]);
                            } catch (e) {
                                console.log(e);
                            }
                        }
                    }
                    if (JKisio(this.params.proximityResults).is(":visible")) {
                        _this = this;
                        JKisio(this.params.proximityResults).animate({'height': newHeight}).promise().then(function () {
                            if (!isIE9) {
                                if (window.proximitySimpleBar === undefined) {
                                    window.proximitySimpleBar = new SimpleBar(JKisio(_this.params.proximityResults)[0]);
                                }
                            }
                        });
                    }
                    if (JKisio(this.params.favoritesPage).is(":visible")) {
                        _this = this;
                        JKisio(this.params.favoritesPage).animate({'height': newHeight}).promise().then(function () {
                            if (!isIE9) {
                                if (window.favoritesPageSimpleBar === undefined) {
                                    window.favoritesPageSimpleBar = new SimpleBar(JKisio(_this.params.favoritesPage)[0]);
                                }
                            }
                        });
                    }
                    if (JKisio(this.params.resultClass).is(":visible")) {
                        JKisio(this.params.resultClass).css({'height': newHeight});
                        // add scroll bar
                        if (!isIE9) {
                            if (window.journeyresultSimpleBar === undefined) {
                                window.journeyresultSimpleBar = new SimpleBar(JKisio(this.params.resultClass)[0]);
                            }
                            el = window.journeyresultSimpleBar;
                        } else {
                            el = JKisio(this.params.resultClass)[0];
                        }
                        // set position of scroll when scrollPosition is defined
                        if (scrollPosition) {
                            if (testSupportsSmoothScroll()) {
                                el.getScrollElement().scrollTo(0, scrollPosition);
                            } else {
                                el.getScrollElement().scrollLeft = 0;
                                el.getScrollElement().scrollTop = scrollPosition;
                            }
                        }
                    }
                    //  Resize de la liste des lignes/arrêts si besoin
                    if (JKisio(this.params.linesSearchResultsClass).is(":visible") && JKisio('#mapid').is(':visible')) {
                        // temporary solution for margin bottom
                        if (JKisio(this.params.buttonBackClass).is(":visible")) {
                            subHeigth = JKisio(this.params.buttonBackClass).outerHeight(true);
                        } else {
                            subHeigth = -25;
                        }
                        JKisio(this.params.linesSearchResultsClass).animate({
                            'height': newHeight + subHeigth
                        });
                        JKisio('#ctp-searchSchedule').outerHeight('auto');
                    }

                    if (JKisio(this.params.trafficClass).is(':visible')) {
                        JKisio(this.params.trafficClass).animate({
                            'height': newHeight
                        });
                    }
                }
                if (JKisio('#mapid').is(':visible')) {
                  _this = this;
                  JKisio(this.params.boardContainerId).animate({'top': newTopBoard + 2}, "fast", "swing", function () {
                      // Manage the GET access for Stop_Area x Line Results
                      if (scheduleSelected !== undefined && scheduleSelected !== '') {
                          JKisio(document.getElementById(scheduleSelected)).trigger('mouseover').trigger('click');
                      }
                      if (JKisio(_this.params.proximityResults).is(":visible")) {
                          if (!isIE9) {
                              if (window.proximitySimpleBar === undefined) {
                                  window.proximitySimpleBar = new SimpleBar(JKisio(_this.params.proximityResults)[0]);
                              }
                          }
                      }
                  });
                }
            } else {
                if (JKisio(this.params.proximityResults).is(":visible")) {
                    if (!isIE9) {
                        if (window.proximitySimpleBar === undefined) {
                            window.proximitySimpleBar = new SimpleBar(JKisio(this.params.proximityResults)[0]);
                        }
                    }
                }
                // Manage the GET access for Stop_Area x Line Results
                if (scheduleSelected !== undefined && scheduleSelected !== '') {
                    JKisio(document.getElementById(scheduleSelected)).trigger('mouseover').trigger('click');
                }
            }
        }
    },

    getHeightHeaderBoard: function () {
        let headerBoard = JKisio(this.params.boardTitleId).outerHeight(true) +
            JKisio(this.params.buttonBackClass).outerHeight(true) +
            JKisio(this.params.journeyPrecisionInfo).outerHeight(true) +
            JKisio(this.params.resultSummaryClass).outerHeight(true) +
            JKisio(this.params.journeySearchAutocomplete).outerHeight(true) +
            JKisio(this.params.scheduleSearchAutocomplete).outerHeight(true) +
            JKisio(this.params.scheduleLineClass).outerHeight(true) +
            JKisio(this.params.boardModesId).outerHeight(true) +
            JKisio(this.params.boardFooterId).outerHeight(true) +
            JKisio(this.params.proximityHeaderId).outerHeight(true) +
            JKisio(this.params.formSubmitId).outerHeight(true) +
            JKisio(this.params.proximityShowHideStopsButton).outerHeight(true);
        return headerBoard;
    }
});

export var positionBoard = Kisio.loadBoardEvents.models.libModels.calculateTopDisplayBoard.extend({});

export function testSupportsSmoothScroll() {
    let supports = false
    try {
        let div = document.createElement('div')
        div.scrollTo({
            top: 0,
            get behavior () {
                supports = true
                return 'smooth'
            }
        })
    } catch (err) {}
    return supports
};