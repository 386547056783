import {getCurrentDisruptions, updatePositionBoard} from '../components/disruptions/index'

const traffic_config = {
    container: '.ctp-traffic',
    network_disruption: '.disruption__network-container',
    network_close: '.ikisio-clear-field',
    network_less_content: '.disruption__network-show-less',
    network_content: '.disruption__network-content',
    modes_section: '.disruption__lines-header',
    future_modes_section: '.future_disruption__lines-header',
    modes_container: '.disruption__lines-body',
    line_back_class: '.disruption__lines-container-back',
    more_content: '.disruption__line-show-less',
    show_active_disruption: '.disruption__active-disruption',
    show_active_disruption_content: '.disruption__active',
    show_future_disruption: '.disruption__future-disruption',
    show_future_disruption_content: '.disruption__future',
    header_active_disruption: ".header__active-disruption",
    header_future_disruption: ".header__future-disruption",
    active_disruptions: ".active-disruptions",
    future_disruptions: ".future-disruptions",
    lines_container_body: '.disruption__lines-container-body',
    height_board: null,
    lines_listener: [],
    show_more_content_listener: []
}

export function trafficContentEventListeners() {
    displayDisruptions();

    document.body.addEventListener('display_traffic_content', function () {
        displayDisruptions();
    });
}

function displayDisruptions() {
    const trafficContainer = document.querySelector(traffic_config.container);
    if (trafficContainer.childNodes.length == 0) {
        getCurrentDisruptions(trafficContainer);
    }
}

export function managerDisruptionHeader() {
    const activeDisruptionEl = document.querySelector(traffic_config.active_disruptions);
    const futureDisruptionEl = document.querySelector(traffic_config.future_disruptions);
    const headerActiveDisruptionEl = document.querySelector(traffic_config.header_active_disruption);
    const headerFutureDisruptionEl = document.querySelector(traffic_config.header_future_disruption);

    headerActiveDisruptionEl?.addEventListener('click', function () {  
        const modesList = document.querySelectorAll(traffic_config.modes_section); 
        [...modesList].forEach(function (modes) {
            modes?.childNodes.forEach(function (mode, index) {
                mode?.classList?.remove('selected');
                if (index == 1) {
                    mode?.classList?.add('selected');
                } 
                
            })
        })

        manageDisplayContainerMode('bloc-1')
        
        if (!headerActiveDisruptionEl?.classList.contains('selected')) {
            headerActiveDisruptionEl.classList.add('selected');
            headerFutureDisruptionEl.classList.remove('selected');

            activeDisruptionEl.classList.remove('hide');
            activeDisruptionEl.classList.add('show');
            futureDisruptionEl.classList.add('hide');
            futureDisruptionEl.classList.remove('show');

        }
    });

    headerFutureDisruptionEl?.addEventListener('click', function () {
        const modesList = document.querySelectorAll(traffic_config.modes_section); 
        [...modesList].forEach(function (modes) {
            modes?.childNodes.forEach(function (mode, index) {
                mode?.classList?.remove('selected');
                if (index == 1) {
                    mode?.classList?.add('selected');
                }
            })
            
        })

        manageDisplayContainerMode('bloc-1')

        if (!headerFutureDisruptionEl?.classList.contains('selected')) {
            headerFutureDisruptionEl.classList.add('selected');
            headerActiveDisruptionEl.classList.remove('selected');

            futureDisruptionEl.classList.remove('hide');
            futureDisruptionEl.classList.add('show');
            activeDisruptionEl.classList.add('hide');
            activeDisruptionEl.classList.remove('show');
        }
    });
}


export function manageNetworkPopupEventListeners() {
    const networkPopups = document.querySelectorAll(traffic_config.network_disruption);

    [...networkPopups].forEach(function (networkPopup) {
        const close = networkPopup.querySelector(traffic_config.network_close);
        close.addEventListener('click', function () {
            networkPopup.remove();
        });

        const showLess = networkPopup.querySelector(traffic_config.network_less_content);
        showLess.addEventListener('click', function () {
            const networkContent = networkPopup.querySelector(traffic_config.network_content);
            if (networkContent.classList.contains('hide')) {
                networkContent.classList.remove('hide');
                updateSeeLess(showLess);

                // update heigth
                updateHeight(networkContent.offsetHeight);
                updatePositionBoard();

                return;
            }

            updateSeeLess(showLess);

            networkContent.classList.add('hide');

            let heightOpnedPopup = 0;
            [...networkPopups].forEach(function (popup) {
                heightOpnedPopup += popup.querySelector(traffic_config.network_content)?.offsetHeight ?? 0;
            });

            // update heigth
            updateHeight(heightOpnedPopup);
            updatePositionBoard();
        });
    });
}

function updateSeeLess(element) {
    for (let j = 0; j < element.children.length; j++) {
        const child = element.children[j];
        if (child?.classList.contains('hide')) {
            child.classList.remove('hide');
            continue;
        }

        child.classList.add('hide');
    }
}

function updateHeight(notifHeight = 0) {
    const trafficBloc = document.querySelector(traffic_config.container);
    if (!traffic_config.height_board) {
        traffic_config.height_board = trafficBloc.offsetHeight;
    }
    trafficBloc.style.height = traffic_config.height_board + notifHeight + 'px';
}

export function manageDisplaySectionMode() {
    const modesList = document.querySelectorAll(traffic_config.modes_section);

    [...modesList].forEach(function (modes) {
        modes?.childNodes.forEach(function (mode, index) {
            // manage click on first selected mode
            if (index == 1) {
                manageDisplayContainerLine('bloc-'+modes.childNodes[index].dataset.index);
            }
    
            mode?.addEventListener('click', function () {
                if (mode.classList.contains('selected')) {
                    return;
                }
    
                modes?.childNodes.forEach(function (mode) {
                    mode?.classList?.remove('selected');
                });
                mode?.classList.add('selected');
                if (mode.dataset.index) {
                    manageDisplayContainerMode('bloc-'+mode.dataset.index);
                }
            });
        });
    })
}

export function manageDisplaySectionFutureMode() {
    const modes = document.querySelector('.modes_section_future');
    modes?.childNodes.forEach(function (mode, index) {
        // manage click on first selected mode
        if (index == 1) {
            manageDisplayContainerLine('bloc-'+modes.childNodes[index].dataset.index);
        }

        mode?.addEventListener('click', function () {
            if (mode.classList.contains('selected')) {
                return;
            }

            modes?.childNodes.forEach(function (mode) {
                mode?.classList?.remove('selected');
            });

            mode?.classList.add('selected');
            if (mode.dataset.index) {
                manageDisplayContainerMode('bloc-'+mode.dataset.index);
            }
        });
    });
}

function manageDisplayContainerMode(bloc) {
    const modesContainerList = document.querySelectorAll(traffic_config.modes_container);
    [...modesContainerList].forEach(function(modesContainer) {
        modesContainer.childNodes.forEach(function (modeContainer) {
            modeContainer?.classList?.remove('selected');
            if (modeContainer?.classList?.contains(bloc)) {
                modeContainer.classList.add('selected');
                // manage click on lines
                manageDisplayContainerLine(bloc);
            }
        });
    })
}

function manageDisplayContainerLine(bloc) {
    const modesContainerList = document.querySelectorAll(traffic_config.modes_container);
    // clear prevent event listeners
    clearLinesListeners();
    [...modesContainerList].forEach(function(modesContainer) {
        const linesContainerList = modesContainer?.querySelectorAll('.'+bloc+'>div');
        [...linesContainerList].forEach(function(linesContainer) {
            linesContainer?.childNodes.forEach(function (line) {
                if (!line?.classList || !line.classList.contains('disruption__lines-item')) {
                    return;
                }

                const callback = function (item) {
                    const blocLineHeigth = line.offsetHeight;
                    // hide others lines
                    linesContainer?.childNodes.forEach(function (item) {
                        item?.classList?.add('hide');

                    });

                    // display line details
                    line.nextElementSibling?.classList.remove('hide');

                    // add event listeners on line details
                    lineDetailsEventListeners(line.nextElementSibling, linesContainer, bloc);

                    // update height
                    updateHeight(line.nextElementSibling.offsetHeight - blocLineHeigth);
                    updatePositionBoard();
                };
                
                line.addEventListener('click', callback);
                [...traffic_config.lines_listener].push({
                line: line,
                listeners: callback
                });
            });
        })
    })
}

function clearLinesListeners() {
    if (traffic_config.lines_listener.length > 0) {
        traffic_config.lines_listener.forEach(function (item) {
           item?.line?.removeEventListener('click', item.listeners);
        });

        traffic_config.lines_listener = [];
    }
}

function clearShowMoreListeners(bloc) {
    if (traffic_config.show_more_content_listener.length > 0) {
        traffic_config.show_more_content_listener.forEach(function (item) {
            if (item[bloc]) {
                item[bloc]?.item?.removeEventListener('click', item[bloc].listeners);
            }
        });

        traffic_config.show_more_content_listener = [];
    }
}

function lineDetailsEventListeners(element, linesContainer, bloc) {
    clearShowMoreListeners(bloc);

    // click on back
    const back = element?.querySelector(traffic_config.line_back_class);
    back?.addEventListener('click', function () {
        element.classList.add('hide');

        // display previous layout
        linesContainer?.childNodes?.forEach(function (line) {
            if (line?.classList?.contains('disruption__lines-item')) {
                line.classList.remove('hide');
            }
        });

        updateHeight();
        updatePositionBoard();
    });

    // click on disruption more content
    const moreContent = element?.querySelectorAll(traffic_config.more_content);
    [...moreContent].forEach(function (item) {
        const callback = function () {
            // manage icon
            item.childNodes.forEach(function (item) {
                if (item?.classList?.contains('hide')) {
                    item.classList.remove('hide');
                } else {
                    item?.classList?.add('hide');
                }
            });

            // display/hide content
            const content = item.parentNode?.nextElementSibling;
            const heightContainer = element.querySelector(traffic_config.lines_container_body);
            if (content?.classList.contains('hide')) {

                content.classList.remove('hide');
                updateHeight(heightContainer.offsetHeight);
            } else {
                content?.classList.add('hide');
                updateHeight(heightContainer.offsetHeight);
            }

            updatePositionBoard();
        };
        item.addEventListener('click', callback);

        traffic_config.show_more_content_listener.push({
            [bloc]: {
                item: item,
                listeners: callback
            }
        });
    });

    // click on current/future disruption
    manageActiveFutureDisruption(element);
}

function manageActiveFutureDisruption(element) {
    const activeDisruptionEl = element.querySelector(traffic_config.show_active_disruption);
    const futureDisruptionEl = element.querySelector(traffic_config.show_future_disruption);
    const futureDisruptionContentEl = element.querySelector(traffic_config.show_future_disruption_content);
    const activeDisruptionContentEl = element.querySelector(traffic_config.show_active_disruption_content);

    activeDisruptionEl?.addEventListener('click', function () {
        if (!activeDisruptionEl?.classList.contains('selected')) {
            activeDisruptionEl.classList.add('selected');
            futureDisruptionEl.classList.remove('selected');

            activeDisruptionContentEl.classList.remove('hide');
            futureDisruptionContentEl.classList.add('hide');

            manageLinesContainerBody(element);
        }
    });

    futureDisruptionEl?.addEventListener('click', function () {
        if (!futureDisruptionEl?.classList.contains('selected')) {
            futureDisruptionEl.classList.add('selected');
            activeDisruptionEl.classList.remove('selected');

            futureDisruptionContentEl.classList.remove('hide');
            activeDisruptionContentEl.classList.add('hide');

            manageLinesContainerBody(element);
        }
    });
}

function manageLinesContainerBody(element) {
    const linesContainer = element.querySelector(traffic_config.lines_container_body);
    updateHeight(linesContainer.offsetHeight);
    updatePositionBoard();
}
